/* variables */
$dots-color: black;

.BrainhubCarousel__dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;

  &.BrainhubCarousel__dots--isRTL {
    direction: rtl;
  }

  .BrainhubCarousel__dot {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    font-size: 0;
    cursor: pointer;
    -webkit-appearance: none;

    &.BrainhubCarousel__dot--selected {
      opacity: 1 !important;
    }

    &:hover {
      opacity: 1;
    }

    &:before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      padding: 0;
      border: none;
      background: $dots-color;
    }
  }

  .BrainhubCarousel__thumbnail {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    font-size: 0;
    cursor: pointer;

    &.BrainhubCarousel__thumbnail--selected {
      opacity: 1 !important;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.BrainhubCarousel__thumbnail[type=button] {
  -webkit-appearance: none;
}

.BrainhubCarousel--isRTL {
  + .BrainhubCarousel__dots {
    direction: rtl;
  }
}
